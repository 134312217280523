export const AlbumCreditsData = [
    
    {
        id: 60,
        image: "https://f4.bcbits.com/img/0030041662_10.jpg",
        alt: "Help Hounds Band",
        artist: "Help Hounds",
        project: "The Knockoning Single",
        credit: "Mastering",
        release: "2022",
        link: "https://open.spotify.com/album/1VW3chRTSbdiG21h44ou1C?si=v-69hH0sRKSqeIu94uRnDg",
    },
    {
        id: 59,
        image: "https://distrokid.imgix.net/http%3A%2F%2Fgather.fandalism.com%2F1398601--F9981EF1-BFA8-4305-87E11B1D002DA5E8--0--16464058--OBGBluewTexture.png?fm=jpg&q=75&w=800&s=7e112397137aa575257a1a95b0dba3f3",
        alt: "On Borken Glass album art",
        artist: "Overcoast",
        project: "On Broken Glass",
        credit: "Mastering",
        release: "2022",
        link: "https://open.spotify.com/album/20yrviqcoHeOqqhcvLsKS1?si=qn-CLYbZQ8WJKbYmvBoIrQ",
    },
    {
        id: 58,
        image: "https://i1.sndcdn.com/artworks-DfZLa9UPZzqx-0-t500x500.jpg",
        alt: "Громко · Крепко · Честно album art",
        artist: "Garlic Kings",
        project: "Громко · Крепко · Честно",
        credit: "Mixing and Mastering",
        release: "2022",
        link: "https://open.spotify.com/album/1DGBl3BkiG7LSmDJYeTMGD?si=tzvTCbVTR5K50JX7fSyWcw",
    },
    {
        id: 57,
        image: "https://i.scdn.co/image/ab67616d0000b273abc86e426d0aa8f75d2c7b11",
        alt: "Eric Brumley album art",
        artist: "Eric Brumley",
        project: "'Hey Kid' single",
        credit: "Mastering",
        release: "2021",
        link: "https://open.spotify.com/track/5zvODiCxXPiu5f0VTsFRvc?si=9fec14ab79d443b2",
    },
    {
        id: 56,
        image: "https://xthoughtswordsactionx.files.wordpress.com/2022/01/a3702509748_10.jpg?w=900",
        alt: "Corrupt Politicians Will Never Set You Free album art",
        artist: "The Decayed",
        project: "Corrupt Politicians Will Never Set You Free",
        credit: "Mastering",
        release: "2021",
        link: "https://open.spotify.com/album/767ACn3yKBcf5dAjVJ3AEt?si=O9AQ6yb5SKykEq9Op-_d2w",
    },
    {
        id: 55,
        image: "https://i1.sndcdn.com/artworks-czaIsn9I9JHZu2dy-Fdz1xA-t240x240.jpg",
        alt: "Light The Sky album art",
        artist: "Eric Brumley",
        project: "'Light The Sky' single",
        credit: "Mastering",
        release: "2020",
        link: "https://soundcloud.com/ericbrumleymusic/light-the-sky",
    },
    {
        id: 54,
        image: "https://i1.sndcdn.com/artworks-qzN0MIFL1VCzZoev-fzrolA-t240x240.jpg",
        alt: "In My Dreams album art",
        artist: "Eric Brumley",
        project: "'In My Dreams' single",
        credit: "Mastering",
        release: "2020",
        link: "https://soundcloud.com/ericbrumleymusic/in-my-dreams",
    },
    {
        id: 53,
        image: "https://is5-ssl.mzstatic.com/image/thumb/Music124/v4/e7/6a/f5/e76af588-50ea-0a87-fc2c-6c31d0145e49/artwork.jpg/500x500bb.webp",
        alt: "More Than Thought album art",
        artist: "Overcoast",
        project: "More Than Thought",
        credit: "Mastering",
        release: "2020",
        link: "https://open.spotify.com/album/2tZmBEOJjgHfBU0uZKt60l?si=0s7luowWSAiNCIZGpBiq3Q",
    },
    {
        id: 52,
        image: "https://gp1.wac.edgecastcdn.net/802892/http_public_production/artists/images/586776/original/crop:x0y0w800h536/hash:1466607389/Group_0105_Cropped_1281215484.jpg?1466607389",
        alt: "Empire State Express album art",
        artist: "Empire State Express",
        project: "Final Show - live",
        credit: "Mixing and Mastering - Live",
        release: "2020",
        link: null,
    },
    {
        id: 51,
        image: "https://f4.bcbits.com/img/a4112631146_16.jpg",
        alt: "Joyride album art",
        artist: "Ballroom Boxer",
        project: "Joyride",
        credit: "Recording, Mixing, and Mastering",
        release: "2020",
        link: "https://ballroomboxer.bandcamp.com/album/joyride",
    },
    {
        id: 50,
        image: "https://i.scdn.co/image/ab67616d00001e02101955e6e515c25cf8f15d9b",
        alt: "Modern Day Sam Malone artwork",
        artist: "Last False Hope",
        project: "'Modern Day Sam Malone' single",
        credit: "Mixing and Mastering",
        release: "2020",
        link: "https://www.youtube.com/watch?v=7CgPNQQDuTg",
    },
    // {
    //     id: 49,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Kaiju",
    //     project: "'Techno Ramm' single",
    //     credit: "Mixing and Mastering",
    //     release: "2020",
    //     link: null,
    // },
    // {
    //     id: 48,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Kaiju",
    //     project: "'I like it, When It's Loud' single",
    //     credit: "Mixing and Mastering",
    //     release: "2020",
    //     link: null,
    // },
    // {
    //     id: 47,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Dual Transient",
    //     project: "self titled",
    //     credit: "Mastering",
    //     release: "2020",
    //     link: null,
    // },
    {
        id: 46,
        image: "https://f4.bcbits.com/img/a3724424865_16.jpg",
        alt: "Where We Are album art",
        artist: "6'10",
        project: "Where We Are",
        credit: "Mixing and Mastering",
        release: "2019",
        link: "https://610music1.bandcamp.com/album/where-we-are-ep-out-now-2019",
    },
    {
        id: 45,
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPTs-AWM0TLUuYVNKMEdj7BOA8zF5YlmE-Og&usqp=CAU",
        alt: "Chicago Folks",
        artist: "Chicago Folks Operatta",
        project: "Ball at the Savoy",
        credit: "Sound Design, Mixing, and Mastering - Live",
        release: "2019",
        link: "https://folksoperetta.org/event/ball-at-the-savoy/",
    },
    {
        id: 44,
        image: "https://f4.bcbits.com/img/a0421375242_16.jpg",
        alt: "A Little Wild album art",
        artist: "Ballroom Boxer",
        project: "A Little Wild",
        credit: "Recording, Mixing, and Mastering",
        release: "2018",
        link: "https://ballroomboxer.bandcamp.com/album/a-little-wild",
    },
    {
        id: 43,
        image: "https://drive.google.com/uc?id=1sGOBFObUrsgypM_DdOcctUW8GWT_nsTA",
        alt: "That's Enough album art",
        artist: "That's Enough",
        project: "self titled EP",
        credit: "Mastering",
        release: "2018",
        link: "https://soundcloud.com/thatsenough?fbclid=IwAR124K6Y8Xxhsj97mcylhfP3S_RYh5i27LAlOP8G_R0EaTqZUby-_mqayl8",
    },
    // {
    //     id: 42,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "PSycHE",
    //     project: "PSycHE HaUs",
    //     credit: "Mastering",
    //     release: "2018",
    //     link: null,
    // },
    {
        id: 41,
        image: "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/ea/14/8b/ea148ba6-1d0f-8218-86c6-a539405761dd/5054526387409_1.jpg/500x500bb.webp",
        alt: "Ya'aburnee album art",
        artist: "Father Figures",
        project: "Ya'aburnee",
        credit: "Mastering",
        release: "2018",
        link: "https://open.spotify.com/album/41jpizlmKD3OkdE2f6swuh?si=e6y04WbrQiqjV5B4gfL2_A",
    },
    {
        id: 40,
        image: "https://f4.bcbits.com/img/a2283018676_16.jpg",
        alt: "Crwon Makers Don'e Wear Crowns album art",
        artist: "A Random Drive",
        project: "Crown Makers Don't Wear Crowns",
        credit: "Mastering",
        release: "2018",
        link: "https://arandomdrive.bandcamp.com/releases",
    },
    {
        id: 39,
        image: "https://i.discogs.com/CkOr-c0Rkirc12yttJhFdZhvvpwS4kaLiguUNIBMpI4/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExMzM5/MjUyLTE1MTQ1Mzc1/MzUtODQxNy5qcGVn.jpeg",
        alt: "Про любовь art",
        artist: "Garlic Kings",
        project: "'Про любовь' single",
        credit: "Mixing and Mastering",
        release: "2017",
        link: "https://open.spotify.com/album/4flP80kLbtpbfnguXvyx9F?si=QlelRzFoRz2Ma2ZBG7Dnmw",
    },
    {
        id: 38,
        image: "https://d10j3mvrs1suex.cloudfront.net/u/156801/c0bba82b5119dbe25b59f0c4f06342248eee1a19/square/album-cover.jpg/!!/meta%3AeyJzcmNCdWNrZXQiOiJiemdsZmlsZXMifQ%3D%3D.jpg",
        alt: "Odd Boat album art",
        artist: "Flatfoot 56",
        project: "Odd Boat",
        credit: "Mixing",
        release: "2016",
        link: "https://flatfoot56.com/odd-boat",
    },
    {
        id: 37,
        image: "https://i.discogs.com/W1lxdIkTgaSc5s2fDZfD12Zo0a5TZKsrbAvNGn1Rs_g/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExMzM5/MjQyLTE1MTQ1Mzcz/MTEtMTI5MC5qcGVn.jpeg",
        alt: "Старые псы album art",
        artist: "Garlic Kings",
        project: "Старые псы",
        credit: "Mixing and Mastering",
        release: "2016",
        link: "https://open.spotify.com/album/0W6XdcC4CEQB2zC9vx8GcB?si=mAm08W6oQhSfdZ8ufqW-eQ",
    },
    {
        id: 36,
        image: "https://f4.bcbits.com/img/a1141192570_16.jpg",
        alt: "Tides album art",
        artist: "Ballroom Boxer",
        project: "'County Jail' single",
        credit: "Mixing",
        release: "2016",
        link: "https://youtu.be/fq74PKi41j4",
    },
    {
        id: 35,
        image: "https://f4.bcbits.com/img/a1141192570_16.jpg",
        alt: "Tides album art",
        artist: "Ballroom Boxer",
        project: "Tides",
        credit: "Vocal Editing",
        release: "2016",
        link: "https://ballroomboxer.bandcamp.com/album/tides-2",
    },
    {
        id: 34,
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPTs-AWM0TLUuYVNKMEdj7BOA8zF5YlmE-Og&usqp=CAU",
        alt: "Chicago Folks",
        artist: "Chicago Folks Operatta",
        project: "Madame Pompadour",
        credit: "Mixing - Live",
        release: "2016",
        link: null,
    },
    {
        id: 33,
        image: "https://f4.bcbits.com/img/a0050833159_16.jpg",
        alt: "Questions and Problems album art",
        artist: "In Exile",
        project: "Questions and Problems",
        credit: "Recording, Mixing, and Mastering",
        release: "2015",
        link: "https://inexilechicago.bandcamp.com/",
    },
    {
        id: 32,
        image: "https://f4.bcbits.com/img/a1874401416_16.jpg",
        alt: "Humble Begginnings of a Roving Soul album art",
        artist: "6'10",
        project: "The Humble Beginnings of a Roving Soul",
        credit: "Mixing",
        release: "2014",
        link: "https://610music1.bandcamp.com/album/the-humble-beginnings-of-a-rovin-soul-full-length-album-out-now-2014",
    },
    
    {
        id: 31,
        image: "https://d10j3mvrs1suex.cloudfront.net/u/156801/f83de579be5a008391043763d8f2de14357499ee/large/flatfoot-56-toil.jpg/!!/meta%3AeyJzcmNCdWNrZXQiOiJiemdsZmlsZXMifQ%3D%3D.jpg",
        alt: "Toil album art",
        artist: "Flatfoot 56",
        project: "Toil",
        credit: "Mixing",
        release: "2012",
        link: "https://flatfoot56.com/toil",
    },
    {
        id: 30,
        image: "https://drive.google.com/uc?id=1XxauRpy1rAUkXvdeKUtRHq1KlckKWNbC",
        alt: "Blind Arrows album art",
        artist: "Man Bird Belly",
        project: "Blind Arrows",
        credit: "Mixing and Mastering",
        release: "2012",
        link: "https://soundcloud.com/manbirdbelly/albums",
    },
    {
        id: 29,
        image: "https://m.media-amazon.com/images/I/716HShTXDHL._SS500_.jpg",
        alt: "Siamese Dream album art",
        artist: "The Smashing Pumpkins",
        project: "Siamese Dream (re-release)",
        credit: "Live DVD Mastering", 
        release: "2011",
        link: "https://www.amazon.com/Siamese-Dream-Deluxe-Smashing-Pumpkins/dp/B0064Z8WQO",
    },
    {
        id: 28,
        image: "https://i.scdn.co/image/ab67616d00001e02e648b57f2d28029776912235",
        alt: "The Frantic album art",
        artist: "The Frantic",
        project: "self titled EP",
        credit: "Mastering",
        release: "2011",
        link: "https://open.spotify.com/album/1lCPTBdCnJSmYv7KpUT5zg?si=OnyJIpycQyKRm7-7uWAtrg",
    },
    {
        id: 27,
        image: "https://is3-ssl.mzstatic.com/image/thumb/Music/2d/60/4d/mzi.jwbpxyoe.jpg/500x500bb.webp",
        alt: "Looking Glass album art",
        artist: "Mia LeBlon",
        project: "Looking Glass",
        credit: "Mastering",
        release: "2011",
        link: "https://open.spotify.com/artist/5Pbh80khO24x4Vb34EOsAn?si=u9hxtNxHRbGQPDUg3VCVpw",
    },
    {
        id: 26,
        image: "https://images-na.ssl-images-amazon.com/images/I/61Tyy7kTM4L._SY355_.jpg",
        alt: "Cast Your Shadow album art",
        artist: "Lights Over Bridgeport",
        project: "Cast Your Shadow",
        credit: "Recording and Mixing",
        release: "2010",
        link: "https://open.spotify.com/album/0x9D8AemWvR8U9RSfud8x7?si=-LoK9ZEbTaeEU4ZhSzyvnA",
    },
    {
        id: 25,
        image: "https://f4.bcbits.com/img/a3725785406_16.jpg",
        alt: "Breaking album art",
        artist: "Mike Reeb",
        project: "Breaking",
        credit: "Mixing",
        release: "2010",
        link: "https://mikereeb.bandcamp.com/track/breaking",
    },
    {
        id: 24,
        image: "https://is2-ssl.mzstatic.com/image/thumb/Music/8f/1f/79/mzi.oalhyoav.jpg/500x500bb.webp",
        alt: "Fornicate the Gutted album art",
        artist: "Bound and Gagged",
        project: "Fornicate the Gutted",
        credit: "Recording, Mixing, and Mastering",
        release: "2019",
        link: "https://open.spotify.com/album/5WVVsDBCMmLwvy324g8dvE?si=bvvIALpYTGm6mMCxi5rcYQ",
    },
    {
        id: 23,
        image: "https://e-cdn-images.dzcdn.net/images/cover/892e3236406e0a4ec88af48a4639ff4f/264x264-000000-80-0-0.jpg",
        alt: "Engines album art",
        artist: "Lights Over Bridgeport",
        project: "Engines",
        credit: "Recording and Mixing",
        release: "2008",
        link: "https://www.deezer.com/sr/album/834007",
    },
    {
        id: 22,
        image: "https://i.scdn.co/image/ab67616d0000b2735d92f076169b0be2e3b322e5",
        alt: "Maps to Chrysalis album art",
        artist: "Meqqa",
        project: "Maps to Chrysalis",
        credit: "Recording and Mixing",
        release: "2009",
        link: "https://open.spotify.com/album/3mmy2LDie9HD8lq1h63Ffr?si=Qob-5xc1SA6F6_UjWMYx8A",
    },
    // {
    //     id: 21,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Surround Sound",
    //     project: "Somebody Say Something",
    //     credit: "Recording and Mixing",
    //     release: "2008",
    //     link: null,
    // },
    {
        id: 20,
        image: "https://aentcdn.azureedge.net/graphics/items/sdimages/b/500/4/7/6/5/1325674.jpg",
        alt: "You're Cordially Invited album art",
        artist: "The Red Tie Affair",
        project: "You're Cordially Invited",
        credit: "Recording and Mixing",
        release: "2008",
        link: "https://daddykool.com/UPC/877319002304",
    },
    {
        id: 19,
        image: "https://is3-ssl.mzstatic.com/image/thumb/Music/1a/ac/92/mzi.fhmsbpkj.tif/300x300bb.webp",
        alt: "Stare album art",
        artist: "The Digital Kill",
        project: "Stare",
        credit: "Recording and Mixing",
        release: "2006",
        link: "https://open.spotify.com/album/51Uygf67CHtxWznGpgSfju?si=6aCWTc2GSF65WNHDLfm8tQ",
    },
    // {
    //     id: 18,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Lights Over Bridgeport",
    //     project: "Sound the Sirens",
    //     credit: "Recording and Mixing",
    //     release: "2004",
    //     link: null,
    // },
    {
        id: 17,
        image: "https://e.snmc.io/i/1200/s/3809fcecbaefa1340730acddefa4a358/1359226",
        alt: "Kill Her While She's youn album art",
        artist: "Failed Resistance",
        project: "Kill Her While She's Young",
        credit: "Recording, Mixing, and Mastering",
        release: "2003",
        link: null,
    },
    {
        id: 16,
        image: "https://f4.bcbits.com/img/a2189643527_16.jpg",
        alt: "The Colour of Gore album art",
        artist: "Reprobation",
        project: "The Colour of Gore",
        credit: "Recording, Mixing, and Mastering",
        release: "2004",
        link: "https://reprobation.bandcamp.com/album/the-colour-of-gore",
    },
    // {
    //     id: 15,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Gazing at Laces",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 14,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Dials to Departure",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 13,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Jason Eickmeir",
    //     project: "solo album",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 12,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "The Dead Racoons",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 11,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Eric Harms",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 10,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Panel 18",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 9,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Inhuman",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 8,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Drowning in Flame",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 7,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Faultline",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 6,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Not a Chance",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 5,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "What Happened to Judy??",
    //     project: "self titled EP",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 4,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "So Far...",
    //     project: "demo",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    // {
    //     id: 3,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Lights Over Bridgeport",
    //     project: "'Fall Without You' single",
    //     credit: "Recording and Mixing",
    //     release: "2003",
    //     link: null,
    // },
    {
        id: 2,
        image: "https://m.media-amazon.com/images/I/51qQMqk6lfL._SX466_.jpg",
        alt: "Justamere Road album art",
        artist: "Stereo South",
        project: "Justamere Road",
        credit: "Auxilary Recording/Production",
        release: "2003",
        link: "https://www.allmusic.com/song/those-who-never-sleep-mt0027880352",
    },
    {
        id: 1,
        image: "https://drive.google.com/uc?id=1K9_lqvoo3vbmYuIutifYSrHnJKWBut2A",
        alt: "Land of the Fee album art",
        artist: "Bite The Hand",
        project: "Land of the Fee",
        credit: "Recording and Mixing",
        release: "2002",
        link: "https://www.soundclick.com/artist/default.cfm?bandID=128489",
    },

]

export default AlbumCreditsData