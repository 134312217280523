export const JbtvCreditsData = [
    {
        id: 143,
        image: "https://obscuresound.com/wp-content/uploads/2007/04/robbers.jpg",
        alt: "Robers on High Street",
        artist: "Robers on High Street",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 142,
        image: "https://gp1.wac.edgecastcdn.net/802892/http_public_production/artists/images/192153/original/crop:x0y0w800h533/hash:1466560032/1341072334_A_Friend_Called_Fire_062sepia_Web.jpg?1466560032",
        alt: "A Friend Called Fire",
        artist: "A Friend Called Fire",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 141,
        image: "https://www.punkrocktheory.com/sites/default/files/styles/image_style_huge_horizontal_rectangle/public/int_flatliners_0.jpg?itok=UyXyn2gQ&c=be9a561a6fda88143320162487f15d76",
        alt: "The Flatliners",
        artist: "The Flatliners",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 140,
        image: "https://images.squarespace-cdn.com/content/v1/561d7296e4b0e681f07991b8/1467125361449-7LFHVG2HDOQQYT6WR4P8/LaDispute_AudiotreeLive_Cover_300x300.jpg?format=500w",
        alt: "La Dispute",
        artist: "La Dispute",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 139,
        image: "https://www.rollingstone.com/wp-content/uploads/2018/06/andrew-wk-68390b1c-b858-491e-90b9-886775927c42.jpg",
        alt: "Andrew W.K.",
        artist: "Andrew W.K.",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 138,
        image: "https://external-preview.redd.it/JeyYVIH4cr0mjG6kAW1HIbcZumMjT2YAmAwifqirFUo.jpg?format=pjpg&auto=webp&s=fc7d17ca89c9cc92fcde0060efb2c41b2867641e",
        alt: "The Parlor Mob",
        artist: "The Parlor Mob",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 137,
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlm0RCc2r7oOUv7IfPTp7DZuNdRzHc3fVaEg&usqp=CAU",
        alt: "Ben Kweller",
        artist: "Ben Kweller",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 136,
        image: "https://i.scdn.co/image/ab6761610000e5ebc503d95417ccc7efbf97b40e",
        alt: "White Wives",
        artist: "White Wives",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 135,
        image: "https://americansongwriter.com/wp-content/uploads/2012/04/williambeckett620x400.jpg",
        alt: "William Beckett",
        subtitle: "from The Academy Is",
        artist: "William Beckett",
        release: 2012, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 134,
        image: "https://i.discogs.com/cQ0wwFCJ155eA6CtS8Kbu1e07JH4-qTI_N5hY6zX3zk/rs:fit/g:sm/q:40/h:300/w:300/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9BLTE3MDcz/ODAtMTI2NDk4Mjc5/Ny5qcGVn.jpeg",
        alt: "Ezra Furman & the Harppons",
        artist: "Ezra Furman & the Harpoons",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 133,
        image: "https://live.staticflickr.com/3100/2481124359_f014f2687c_b.jpg",
        alt: "Jon Walker",
        artist: "Jon Walker",
        subtitle: "from Panic at the Disco",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 132,
        image: "https://www.nme.com/wp-content/uploads/2020/03/Frank-Turner.jpg",
        alt: "Frank Turner",
        artist: "Frank Turner",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 131,
        image: "https://i0.wp.com/www.bowlingforsoup.com/wp-content/uploads/BFS-Header-guys-cutout.png?resize=1060%2C754",
        alt: "Bowling For Soup",
        artist: "Bowling for Soup",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 130,
        image: "https://i.scdn.co/image/ab67616d0000b273028ddff45e833f56e5473e7e",
        alt: "Seven Day Sonnet",
        artist: "Seven Day Sonnet",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 129,
        image: "https://images.ukfestivalguides.com/artist_images/voxhaul-broadcast.jpg",
        alt: "Foxhaul Broadcast",
        artist: "Foxhaul Broadcast",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 128,
        image: "https://www.wnrn.org/wp-content/uploads/2021/05/dawes.jpg",
        alt: "DAWES",
        artist: "DAWES",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 127,
        image: "https://onmilwaukee.com/images/articles/gi/gigsdotdotdot/gigsdotdotdot_fullsize_story1.jpg",
        alt: "DOT DOT DOT",
        artist: "DOT DOT DOT",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 126,
        image: "https://www.independent.com/wp-content/uploads/2010/12/10/Artslife257-TOTW-Tommy-Cred.jpg",
        alt: "Tommy and the High Pilots",
        artist: "Tommy and the High Pilots",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 125,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Stamps",
    //     release: 2011, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 124,
        image: "https://i.scdn.co/image/ab6761610000e5eb2b2c1b2f838286c0f25a388f",
        alt: "Bouncing Souls",
        artist: "Bouncing Souls",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 123,
        image: "https://i1.sndcdn.com/avatars-000006785614-3hzsyg-t500x500.jpg",
        alt: "John Drake and The Shakes",
        artist: "John Drake and the Shakes",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 122,
        image: "https://img.washingtonpost.com/rf/image_480w/WashingtonPost/Content/Blogs/click-track/StandingArt/skysaw.jpg?uuid=TtmkSqJuEeCDctuio8wgiA",
        alt: "skysaw",
        artist: "Skysaw",
        subtitle: "featuring Jimmy Chamberlin",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 121,
        image: "https://s.wsj.net/public/resources/images/BN-HC556_NYJD02_G_20150224161833.jpg",
        alt: "JD McPherson",
        artist: "JD McPherson",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 120,
        image: "https://thisis-images.scdn.co/37i9dQZF1DZ06evO1JIytk-large.jpg",
        alt: "Anti Flag",
        artist: "Anti FLag",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 119,
        image: "https://images.sk-static.com/images/media/profile_images/artists/161475/huge_avatar",
        alt: "Lisa Loeb",
        artist: "Lisa Loeb",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 118,
        image: "https://i.ticketweb.com/i/00/09/10/56/59_Original.jpg?v=5",
        alt: "New Found Glory",
        artist: "New Found Glory",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 117,
        image: "https://img.redbull.com/images/c_limit,w_1500,h_1000,f_auto,q_auto/redbullcom/2017/11/14/3e889566-b7ae-465c-9eed-b9332b3bc532/awolnation-portrait",
        alt: "AWOLNATION",
        artist: "AWOLNATION",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 116,
        image: "https://f4.bcbits.com/img/0003407446_10.jpg",
        alt: "White Mystery",
        artist: "White Mystery",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 115,
        image: "https://spillmagazine.com/wp-content/uploads/2016/05/WantMore.jpeg",
        alt: "JC Brooks",
        artist: "JC Brooks",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 114,
        image: "https://bloximages.newyork1.vip.townnews.com/tulsaworld.com/content/tncms/assets/v3/editorial/0/de/0de28aa2-8b8e-57a1-8c10-a4c50956d218/523d7b9aeca35.preview-300.jpg?crop=284%2C284%2C8%2C0&resize=1200%2C1200&order=crop%2Cresize",
        alt: "Kill Hannah",
        artist: "Kill Hannah",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 113,
        image: "https://media.npr.org/music/sotd/2009/03/popes300-da1c8d1830608a96faf77c78316431a668af11e2-s1100-c50.jpg",
        alt: "Smoking Popes",
        artist: "Smoking Popes",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 112,
        image: "https://f4.bcbits.com/img/a2799605944_10.jpg",
        alt: "Maps and Atlases",
        artist: "Maps and Atlases",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 111,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Suns",
    //     release: 2011, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 110,
        image: "https://yt3.ggpht.com/ytc/AKedOLRFLqF2R3YrRWgn-XCw25_fb6ybrE82tyIIANP_cQ=s900-c-k-c0x00ffffff-no-rj",
        alt: "Fitz and the Tantrums",
        artist: "Fitz and the Tantrums",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 109,
        image: "https://i1.sndcdn.com/artworks-000035355403-bobb48-t500x500.jpg",
        alt: "David Costa",
        artist: "David Costa",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 108,
        image: "https://direct.rhapsody.com/imageserver/images/alb.11199066/500x500.jpg",
        alt: "Authority Zero",
        artist: "Authority Zero",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 107,
    //     image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/No-album-art.png",
    //     alt: "No Image",
    //     artist: "Urbanites",
    //     release: 2011, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 106,
        image: "https://f4.bcbits.com/img/0004337420_10.jpg",
        alt: "In Tall Buildings",
        artist: "In Tall Buildings",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 105,
        image: "https://static.spin.com/files/field/image/090715-free-energy.jpg",
        alt: "Free Energy",
        artist: "Free Energy",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 104,
        image: "https://www.reggieslive.com/wp-content/uploads/The-Lifeline-600x431.jpg",
        alt: "The Lifeline",
        artist: "The Lifeline",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording and Mastering",
        link: null,
    },
    {
        id: 103,
        image: "https://dynamicmedia.livenationinternational.com/Media/u/q/d/3a034462-85f4-4a03-a0ca-32f035dd7a61.jpg",
        alt: "Danko Jones",
        artist: "Danko Jones",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 102,
        image: "https://alchetron.com/cdn/fake-problems-7d97e586-5819-4fa3-9d8a-763c04f650a-resize-750.jpeg",
        alt: "Fake Problems",
        artist: "Fake Problems",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 101,
        image: "https://i.scdn.co/image/ab6761610000e5eb9f3fa8ece16dda7f476972ee",
        alt: "Cameron McGill",
        artist: "Cameron McGill & What Army",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 100,
        image: "https://images.squarespace-cdn.com/content/v1/5692c8eda2bab8b5b8e7bad2/1461038686391-KC7T143Y0OY5ZCEUHOM0/Marc+Hauser+Blah+Blah+Blah+Phills+Let.jpg?format=750w",
        alt: "blah blah blah",
        artist: "Blah Blah Blah",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 99,
        image: "https://i.scdn.co/image/43223607a8a41031da022afc39fe8e99df3cdddf",
        alt: "The Arrivals",
        artist: "The Arrivals",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 98,
        image: "https://static.spin.com/files/field/image/100910-semi-precious-weapons.jpg",
        alt: "Semi Precious Weapons",
        artist: "Semi Precious Weapons",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 97,
        image: "https://m.media-amazon.com/images/I/81hByPTLYtL._SS500_.jpg",
        alt: "Jerome Holloway",
        artist: "Jerome Holloway",
        release: 2011, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 96,
        image: "https://m.media-amazon.com/images/M/MV5BYzU2M2U2NDktZmQzMC00Y2VlLWJkNDgtYzk5ZDJjZmJiNmIxXkEyXkFqcGdeQXVyNTI5NjIyMw@@._V1_.jpg",
        alt: "Portugal the Man",
        artist: "Portugal the Man",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 95,
        image: "https://images.sk-static.com/images/media/img/col3/20130402-184629-061028.jpg",
        alt: "Alberta Cross",
        artist: "Alberta Cross",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 94,
        image: "https://m.media-amazon.com/images/I/614zkBoTYbL.jpg",
        alt: "Against Me!",
        artist: "Against Me!",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 93,
        image: "https://www.gannett-cdn.com/authoring/2017/05/11/NTEG/ghows-WT-4dec7f66-2feb-3c05-e053-0100007f6300-c92fb26a.jpeg?width=660&height=660&fit=crop&format=pjpg&auto=webp",
        alt: "ayside",
        artist: "Bayside",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 92,
        image: "https://s3.amazonaws.com/bit-photos/large/12036843.jpeg",
        alt: "ALO",
        artist: "ALO",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 91,
        image: "https://lastfm.freetls.fastly.net/i/u/ar0/05531023b9b54cf082e6cdb057e97448.jpg",
        alt: "Dan Black",
        artist: "Dan Black",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 90,
        image: "https://static.spin.com/files/field/image/101011-bear-in-heaven.jpg",
        alt: "Bear in Heaven",
        artist: "Bear in Heaven",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 89,
        image: "https://www.independent.com/wp-content/uploads/2009/11/16/PRJPreview201Flobots-Credit.jpg",
        alt: "Flobots",
        artist: "Flobots",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 88,
        image: "https://townsquare.media/site/366/files/2021/10/attachment-Foxy-Shazam-2021.jpg?w=1200&h=0&zc=1&s=0&a=t&q=89g",
        alt: "Foxy Shazam",
        artist: "Foxy Shazam",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 87,
        image: "https://lastfm.freetls.fastly.net/i/u/ar0/3e3f309d325447068bce7437c618938d.jpg",
        alt: "Freelance Whales",
        artist: "Freelance Whales",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 86,
        image: "https://media.npr.org/assets/music/news/2010/06/gaslight_anthem-ff194098b59b674f02d0293e5e9f89757be31524-s1100-c50.jpg",
        alt: "The Gaslight Anthem",
        artist: "The Gaslight Anthem",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 85,
        image: "https://www.scannerzine.com/Dead%20To%20Me/Dead%20To%20me%20band.jpg",
        alt: "Dead to Me",
        artist: "Dead to Me",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 84,
        image: "https://www.chicagomag.com/wp-content/archive/images/2012/0312/C201203-A-Gold-Motel-band-photo.jpg",
        alt: "Gold Motel",
        artist: "Gold Motel",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 83,
        image: "https://hips.hearstapps.com/seventeen/assets/cm/15/07/54db049484746_-_heychamp_img02_hiresjm.jpg",
        alt: "Hey Champ",
        artist: "Hey Champ",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 82,
        image: "https://media.wnyc.org/i/800/0/c/85/photologue/photos/diane.jpg",
        alt: "Diane Birch",
        artist: "Diane Birch",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 81,
        image: "https://lyricstranslate.com/files/styles/artist/public/one%20eskimo.jpg",
        alt: "One Eskimo",
        artist: "One Eskimo",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 80,
        image: "https://direct.rhapsody.com/imageserver/images/Art.30161/633x422.jpg",
        alt: "RX Bandits",
        artist: "RX Bandits",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 79,
        image: "https://first-avenue.com/wp-content/uploads/2020/01/Scott_Lucas-photo-Jim_Newberry.jpg",
        alt: "Scott Lucas and the Married Men",
        artist: "Scott Lucas and the Married Men",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 78,
        image: "https://alchetron.com/cdn/runner-runner-band-197c37b1-6b91-4929-8e8a-037d6dca78e-resize-750.jpg",
        alt: "Runner Runner",
        artist: "Runner Runner",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 76,
        image: "https://media.altpress.com/uploads/2018/06/The-Swellers-2012-620.jpg",
        alt: "The Swellers",
        artist: "The Swellers",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 75,
        image: "https://www.nme.com/wp-content/uploads/2020/07/2020_McClymonts_IP_2000x1270.jpg",
        alt: "The McClymonts",
        artist: "The McClymonts",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 74,
        image: "https://pbs.twimg.com/profile_images/1207629102/thefranticpromos050_edit_-_lores_400x400.jpg",
        alt: "The Frantic",
        artist: "The Frantic",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 73,
        image: "https://www.thebrokedowns.com/image/cache/catalog/The-Brokedowns-by-Katie-Hovland-2000x1224w.jpg",
        alt: "The Brokedowns",
        artist: "The Brokedowns",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 72,
        image: "https://64.media.tumblr.com/8bd8a7f65a2e0ae81b8c22bd1e5a7f73/1c18a4e4f27e1f50-28/s500x750/43572a778d1b74f4632229755379de56f1609e88.png",
        alt: "Terrible Things",
        artist: "Terrible Things",
        subtitle: "featuring Fred Mascherino",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 71,
        image: "https://bloximages.chicago2.vip.townnews.com/lacrossetribune.com/content/tncms/assets/v3/editorial/9/4c/94cacf40-d08e-5665-aa9b-841be0f997a4/4ca629c07d6ff.image.jpg",
        alt: "The Audition",
        artist: "The Audition",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 70,
        image: "https://pan-african-music.com/wp-content/uploads/2022/02/%C2%A9thomasdorn_3601.jpg",
        alt: "Tinariwen",
        artist: "Tinariwen",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 69,
        image: "https://themaneater.com/wp-content/uploads/2021/07/MOVEempires-650x600.jpg",
        alt: "Empires",
        artist: "Empires",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 68,
        image: "https://lastfm.freetls.fastly.net/i/u/ar0/e19d7963a734486990da785bc8722546.jpg",
        alt: "Hot Hot Heat",
        artist: "Hot Hot Heat",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 67,
        image: "https://assets.rbl.ms/10379493/origin.jpg",
        alt: "Nneka",
        artist: "Nneka",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 66,
        image: "https://www.13stitchesmagazine.com/wp-content/uploads/2021/05/riverboat-gamblers.jpg",
        alt: "Riverboat Gamblers",
        artist: "Riverboat Gamblers",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 65,
        image: "https://www.upsetmagazine.com/images/article/_crop1500x1000/madinalake.jpg",
        alt: "Madina Lake",
        artist: "Madina Lake",
        subtitle: "Emmy nominiated",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 64,
        image: "https://townsquare.media/site/366/files/2019/06/Strung-Out.jpg?w=980&q=75",
        alt: "Strung Out",
        artist: "Strung Out",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 63,
        image: "https://api.wbez.org/v2/images/bc25d7f0-4901-4dbd-91c7-fbee25149c76.jpg?width=900&height=675&mode=FILL",
        alt: "I Fight Dragons",
        artist: "I Fight Dragons",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 62,
        image: "https://s1.ticketm.net/dam/a/35c/5067e3ae-9355-4470-95cf-837eb94d235c_1469541_TABLET_LANDSCAPE_LARGE_16_9.jpg",
        alt: "Circa Survive",
        artist: "Circa Survive",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 61,
        image: "https://bigtakeover.com/images/10984.jpg",
        alt: "Street Dogs",
        artist: "Street Dogs",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 60,
        image: "https://images.squarespace-cdn.com/content/v1/561d7296e4b0e681f07991b8/1466712293988-5MV85LYNYSSQB030MDQQ/DeathOnTwoWheels_AudiotreeLive_Cover_300x300.jpg?format=500w",
        alt: "Death on Two Wheels",
        artist: "Death on Two Wheels",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 59,
        image: "https://media.npr.org/assets/music/programs/worldcafe/2010/10/jpch-3f9bb65050bed05ec8a2ce0126f850d559cd03c6-s1100-c50.jpg",
        alt: "JP Chrissies",
        artist: "JP Chrissie & the Fairground Boys",
        subtitle: "featuring Chrissie Hynde",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 58,
        image: "https://www.nj.com/resizer/Sz4jkX3YWYCFQtXsBC27VNOq9ec=/1280x0/smart/advancelocal-adapter-image-uploads.s3.amazonaws.com/image.nj.com/home/njo-media/width2048/img/entertainment_impact_music/photo/steel-trainjpg-8fd44b24ec603ffa.jpg",
        alt: "Steel Train",
        artist: "Steel Train",
        subtitile: "featuring Jack Antonoff",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 57,
        image: "https://upload.wikimedia.org/wikipedia/commons/f/fb/AM_Taxi_2019.jpg",
        alt: "AM Taxi",
        artist: "AM Taxi",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 56,
        image: "https://cdn.glidemagazine.com/wp-content/uploads/2010/07/31072934/silversunreview.jpg",
        alt: "Silversun Pickups",
        artist: "Silversun Pickups",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 55,
        image: "https://f4.bcbits.com/img/a3882666180_16.jpg",
        alt: "The Fireship",
        artist: "The Fireship",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording and Mastering",
        link: null,
    },
    // {
    //     id: 54,
    //     image: "",
    //     alt: "",
    //     artist: "Kirkland",
    //     project: "Live to tape JBTV performance",
    //     release: 2010, 
    //     credit:"Recording and Mastering",
    //     link: null,
    // },
    {
        id: 53,
        image: "https://media.npr.org/assets/img/2013/02/20/love-quarry-smile_wide-3ac4bd5bb4c191051a0bcc548b9197843cc684e7-s1100-c50.jpg",
        alt: "Cloud Cult",
        artist: "Cloud Cult",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 52,
    //     image: "",
    //     alt: "",
    //     artist: "Kellen and Me",
    //     release: 2010, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 51,
        image: "https://static.onecms.io/wp-content/uploads/sites/6/2018/08/pwt-press-photo-colin-lane-1-2000.jpg",
        alt: "The Plain White T's",
        artist: "The Plain White T's",
        release: 2010, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 50,
    //     image: "",
    //     alt: "",
    //     artist: "Todd Carey and Alexia Wilkinson",
    //     release: 2009, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 49,
        image: "https://first-avenue.com/wp-content/uploads/2020/01/tubring.jpg",
        alt: "Tubring",
        artist: "Tubring",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 48,
        image: "https://www.uberproaudio.com/images/stories/Interviews/Mike_Herrera/tumbledown_by_jered%20scott_small.jpg",
        alt: "Tumbledown",
        artist: "Tumbledown",
        subtitle: "featuring Mike Herera",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 47,
        image: "https://cdn.glidemagazine.com/wp-content/uploads/2010/08/31073820/vanghost.jpg",
        alt: "Van Ghost",
        artist: "Van Ghost",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 46,
    //     image: "",
    //     alt: "",
    //     artist: "The Insecurities",
    //     release: 2009, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 45,
        image: "https://www.billboard.com/wp-content/uploads/media/Company-of-Thieves-press-photo-by-Shervin-Lainez-2018-billboard-1548.jpg",
        alt: "Company of Theives",
        artist: "Company of Theives",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 44,
        image: "https://a2-images.myspacecdn.com/images03/2/2cfc772bc5e6418bb45c39e1167e1655/300x300.jpg",
        alt: "Curumin",
        artist: "Curumin",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 43,
        image: "https://i1.sndcdn.com/avatars-000006464377-3e1wr1-t500x500.jpg",
        alt: "Electric Touch",
        artist: "Electric Touch",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 42,
        image: "https://themilitiagroup.com/wp-content/uploads/2018/03/881_everybody_.jpg",
        alt: "Everybody Else",
        artist: "Everybody Else",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 41,
        image: "https://img.cincymusic.com/social/mobile/bands/532e19a646620.jpg",
        alt: "Ha Ha Tonka",
        artist: "Ha Ha Tonka",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 40,
    //     image: "",
    //     alt: "",
    //     artist: "Pictures and Sound",
    //     release: 2009, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 39,
        image: "https://64.media.tumblr.com/tumblr_m5kfrvtCfC1rws3bbo1_1280.jpg",
        alt: "Rachel McClusky",
        artist: "Rachel McClusky",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 38,
        image: "https://img.discogs.com/nYYK_pnhWam5arHgiUcGF-aW8HA=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/A-2969313-1595291839-1795.jpeg.jpg",
        alt: "The Lusties",
        artist: "The Lusties",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 37,
        image: "https://d1v5r0wgdk0cf3.cloudfront.net/photos/7/a/f/7af8c82bab4c.jpg",
        alt: "Villians of Verona",
        artist: "Villains of Verona",
        release: 2009, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 36,
        image: "https://photos.bandsintown.com/large/8306993.jpeg",
        alt: "The Dandy Warhols",
        artist: "The Dandy Warhols",
        subtitle: "Emmy nominiated",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 35,
        image: "https://m.media-amazon.com/images/M/MV5BODQ3MzdlMjMtYTFlZi00YWIxLWE5MTgtNjU3NTIzMGQzYzk3XkEyXkFqcGdeQXVyMzM4MjM0Nzg@._V1_.jpg",
        alt: "MXPX",
        artist: "MXPX",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 34,
        image: "https://music.allaccess.com/wp-content/uploads/2017/02/the-kin1.jpg",
        alt: "The Kin",
        artist: "The Kin",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 33,
        image: "https://lastfm.freetls.fastly.net/i/u/avatar170s/b5434456f0ee45b090d4dbfeb13e90bd",
        alt: "Treologic",
        artist: "Treologic",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 32,
        image: "https://resources.tidal.com/images/e26cbc9a/a07b/4e2a/9911/6a0d8d9fc08d/750x750.jpg",
        alt: "Apollo Sunshine",
        artist: "Apollo Sunshine",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 31,
        image: "https://e.snmc.io/i/1200/s/28a2d21d310587d36e0b068a3a4c2aac/3405889",
        alt: "The Blisters",
        artist: "The Blisters",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 30,
        image: "https://chicago.gopride.com/c/I/19292-57881.jpg",
        alt: "Greg Laswell",
        artist: "Greg Laswell",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 29,
        image: "https://immersiveatlanta.com/wp-content/uploads/2018/06/gringostar5.jpg",
        alt: "Gringo Star",
        artist: "Gringo Star",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 28,
        image: "https://stagedoorimages.broadwayworld.com/wp-content/uploads/2020/12/25170942/295AF543-CAB2-4232-97EE-676489E75441-scaled-e1607445831141-600x600.jpg",
        alt: "Lauren Molina",
        artist: "Lauren Molina",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 27,
    //     image: "",
    //     alt: "",
    //     artist: "Plane",
    //     release: 2008, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 26,
        image: "https://i1.wp.com/www.soundemporiumstudios.com/wp-content/uploads/2018/03/pop-evil-2.jpg?fit=960%2C639&ssl=1",
        alt: "Pop Evil",
        artist: "Pop Evil",
        release: 2008, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 25,
        image: "https://external-preview.redd.it/YQ8t0QDZv4kTyOBlnAMIaGja11YXahOyauVg9h2Uwzg.jpg?auto=webp&s=53a9835293f4549540dd771275e5416fcd015a11",
        alt: "Black Box",
        artist: "Black Box",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 24,
        image: "https://www.rcreader.com/sites/default/files/styles/inside/public/field/image/2019-01/flatfoot56.jpg?itok=rxgWGphX",
        alt: "Flatfoot 56",
        artist: "Flatfoot 56",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 23,
        image: "https://lastfm.freetls.fastly.net/i/u/ar0/dc0b4b113c1744399ae7f401072e5fe8.jpg",
        alt: "Goodbye Satellite",
        artist: "Goodbye Satellite",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 22,
        image: "https://upload.wikimedia.org/wikipedia/en/4/4b/Jarrod_Weeks_and_Jackson_O%27Connell-Barlow_of_Grand_Buffet_%282003%29.jpg",
        alt: "Grand Buffet",
        artist: "Grand Buffet",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 21,
        image: "https://upload.wikimedia.org/wikipedia/en/1/11/Is_promo.jpg",
        alt: "Iscintilla",
        artist: "Iscintilla",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 20,
    //     image: "",
    //     alt: "",
    //     artist: "June",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 19,
        image: "https://i.ytimg.com/vi/E27TWAVVza8/maxresdefault.jpg",
        alt: "Kill Hannah live at Metro Chicago",
        artist: "Kill Hannah",
        subtitle: "Live at Metro Chicago",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 18,
    //     image: "",
    //     alt: "",
    //     artist: "Meqqa",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 17,
        image: "https://bloximages.newyork1.vip.townnews.com/nuvo.newsnirvana.com/content/tncms/assets/v3/editorial/9/3c/93c278e9-0392-526e-b370-2b0333478972/58bdc4fdcf097.image.jpg?crop=646%2C485%2C0%2C80&resize=1200%2C900&order=crop%2Cresize",
        alt: "Mike Reeb",
        artist: "Mike Reeb",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 16,
        image: "https://pbs.twimg.com/media/DXzEGKIVQAAwOvw.jpg",
        alt: "The Tossers",
        artist: "The Tossers",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    // {
    //     id: 15,
    //     image: "",
    //     alt: "",
    //     artist: "The Red Tie Affair",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    // {
    //     id: 14,
    //     image: "",
    //     alt: "",
    //     artist: "The Young Sea",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    // {
    //     id: 13,
    //     image: "",
    //     alt: "",
    //     artist: "The Wisebloods",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    // {
    //     id: 12,
    //     image: "",
    //     alt: "",
    //     artist: "This is Me Smilling",
    //     release: 2007, 
    //     project: "Live to tape JBTV performance",
    //     credit:"Recording, mixing, and Mastering",
    //     link: null,
    // },
    {
        id: 11,
        image: "https://images.thestar.com/oufXER2IAX-0ZDKRR8eH4O3waaU=/1280x1024/smart/filters:cb(1603297398884)/https://www.thespec.com/content/dam/thespec/entertainment/music/opinion/2020/10/21/burlington-band-finger-eleven-tops-100-million-on-spotify-with-song-paralyzer/finger.jpg",
        alt: "Finger 11",
        artist: "Finger 11",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 10,
        image: "https://mediaproxy.salon.com/width/1200/https://media.salon.com/2016/11/glen_phillips.jpg",
        alt: "Glen Phillips",
        artist: "Glen Phillips",
        subtitle: "of Toad the Wet Srocket",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 9,
        image: "https://cdn.bandmix.com/bandmix_us/media/155/155464/136948-p.jpg",
        alt: "Love in October",
        artist: "love in October",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 8,
        image: "https://lastfm.freetls.fastly.net/i/u/ar0/25dcbe47a901463abfb7169d5d65a315.jpg",
        alt: "Johny Rumble",
        artist: "Johny Rumble",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 7,
        image: "https://countrymusiclife.com/wp-content/uploads/2013/02/Keaton-Simons-Beautiful-Pain-Album-580x364.png",
        alt: "Keaton Simons",
        artist: "Keaton Simons",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 6,
        image: "https://bloximages.chicago2.vip.townnews.com/chippewa.com/content/tncms/assets/v3/editorial/d/41/d41ffaa9-28c3-5cb2-83fc-c60d0a04de27/5c411e874c8ac.image.jpg?resize=1200%2C918",
        alt: "Michael Charles",
        artist: "Michael Charles",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 5,
        image: "https://i.discogs.com/lSrNK83gc6fIurqqDHd3La9XVagqU6XoaopMnhS6EJE/rs:fit/g:sm/q:90/h:399/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9BLTM5Mzcx/NTMtMTQwNjgzMjgy/OS0zNzg5LmpwZWc.jpeg",
        alt: "Swizzle Tree",
        artist: "Swizzle Tree",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 4,
        image: "https://myspace.ge/file/pic/user/fb_228525_768dcd1c91b5d6bccc07cda80ed3fb2e.jpg",
        alt: "Ted Aliota",
        artist: "Ted Aliota",
        subtitle: "of Aliota Haynes",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 3,
        image: "https://gp1.wac.edgecastcdn.net/802892/http_public_production/artists/images/62817/original/resize:248x186/crop:x0y72w600h450/hash:1466544834/l_3a1623bf5840ff95183e434eb76940c7.jpg?1466544834",
        alt: "The Rikters",
        artist: "The Rikters",
        release: 2007, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 2,
        image: "https://mmusicmag.com/m/wp-content/uploads/2013/09/30-Seconds-to-Mars-Issue-No28.jpg",
        alt: "30 Seconds to Mars",
        artist: "30 Seconds to Mars",
        release: 2006, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 1,
        image: "https://bloximages.newyork1.vip.townnews.com/tulsaworld.com/content/tncms/assets/v3/editorial/0/de/0de28aa2-8b8e-57a1-8c10-a4c50956d218/523d7b9aeca35.preview-300.jpg?crop=284%2C284%2C8%2C0&resize=1200%2C1200&order=crop%2Cresize",
        alt: "Kill Hannah",
        artist: "Kill Hannah",
        release: 2006, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
    {
        id: 0,
        image: "https://i1.sndcdn.com/avatars-000112653475-yd5x7e-t500x500.jpg",
        alt: "Raised on Zenith",
        artist: "Raised on Zenith",
        release: 2006, 
        project: "Live to tape JBTV performance",
        credit:"Recording, mixing, and Mastering",
        link: null,
    },
]
 
export default JbtvCreditsData
